import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Button, FormControl, InputGroup, Modal } from 'react-bootstrap';
import { updateSelectedCandidate } from '../../store/global/actions';
import { addTag, getTags, removeTag } from '../../store/employer/actions';

function Tags(props) {
  const { selectedCandidate, updateSelectedCandidate, addTag, getTags, removeTag } = props;
  const [tags, setTags] = useState([]);
  const [tag_name, setTagName] = useState('');
  const [error, setError] = useState(false);

  useEffect(() => {
    if (selectedCandidate?.type === 'tags') fetchTags();
  }, [selectedCandidate]);

  const fetchTags = async () => {
    const { data } = await getTags(selectedCandidate.id.toString());
    setTags(data);
  };

  const onSave = async (e) => {
    e.preventDefault();
    if (!tag_name) {
      setError(true);
      return;
    }

    setError(false);
    const { error } = await addTag({
      cv_id: selectedCandidate.id,
      tag_name
    });

    if (!error) {
      setTagName('');
      await fetchTags();
    }
  };

  const onRemoveTag = async (tag) => {
    const { error } = await removeTag(tag);

    if (!error) await fetchTags();
  };

  const handleClose = () => {
    updateSelectedCandidate(null);
  };

  return (
    <Modal
      show={selectedCandidate && selectedCandidate.type === 'tags'}
      backdrop="static"
      centered
      onHide={handleClose}
      className="p-0">
      <Modal.Header closeButton className="py-2">
        <Modal.Title>Candidate Notes</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={onSave}>
          <InputGroup>
            <FormControl
              placeholder="Add Candidate Note"
              className="fw-500 rounded-0 shadow-none"
              value={tag_name}
              onChange={(e) => setTagName(e.target.value)}
            />
            <Button
              variant="outline-primary"
              className="bg-blue text-white text-capitalize shadow-none px-4 fw-500 rounded-0"
              type="submit"
              onClick={onSave}>
              Save
            </Button>
          </InputGroup>
        </form>
        {error && <span className="invalid-feedback d-block">Please enter note</span>}
        <ul className="p-0 m-0 list-style-none d-flex align-items-center flex-wrap mt-3">
          {_.map(tags, (tag, i) => (
            <li className="me-2 my-1 lh-base" key={i}>
              <span className="bg-dark-light px-3 py-1 rounded-pill fw-500 fs-14 text-blue lh-base text-capitalize">
                {tag.tag_name}
                <button className="btn p-0 border-0" type="button">
                  <i
                    className="fa fa-close ms-2 text-secondary"
                    onClick={() => onRemoveTag(tag.tag_id)}></i>
                </button>
              </span>
            </li>
          ))}
        </ul>
      </Modal.Body>
    </Modal>
  );
}

export default connect((state) => ({ selectedCandidate: state.global.selectedCandidate }), {
  addTag,
  getTags,
  removeTag,
  updateSelectedCandidate
})(Tags);
