import { DEFAULT_ACTION, LOGIN } from '../action-types';
import axios from 'axios';
import {
  UPDATE_NOTIFICATION,
  SHOW_LOADING,
  UPDATE_HEADER_DATA,
  UPDATE_CANDIDATE_FILTERS
} from '../mutations';
import httpService from '../../network/http-service';
import { handleError } from '../global/actions';
import { API_ENDPOINT } from '../api-action';

export function getDashboard() {
  return async function (dispatch) {
    dispatch({ type: SHOW_LOADING, payload: true });
    try {
      const response = await httpService.get({ action: API_ENDPOINT.dashboard });
      return response.data?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(LOGIN, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function getHeaderData() {
  return async function (dispatch) {
    dispatch({ type: SHOW_LOADING, payload: true });
    try {
      const response = await httpService.get({ action: API_ENDPOINT.headerData });
      dispatch({ type: UPDATE_HEADER_DATA, payload: response.data?.data });
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(LOGIN, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function orderHistory() {
  return async function (dispatch) {
    dispatch({ type: SHOW_LOADING, payload: true });
    try {
      const response = await httpService.get({ action: API_ENDPOINT.orderHistory });
      return response.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(LOGIN, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function allocationHistory() {
  return async function (dispatch) {
    dispatch({ type: SHOW_LOADING, payload: true });
    try {
      const response = await httpService.get({ action: API_ENDPOINT.allocationHistory });
      return response.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(LOGIN, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function jobList(payload) {
  return async function (dispatch) {
    dispatch({ type: SHOW_LOADING, payload: true });
    try {
      const response = await httpService.get({ action: API_ENDPOINT.jobList, query: payload });
      return response.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(LOGIN, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function premiumRequest(payload) {
  return async function (dispatch) {
    dispatch({ type: SHOW_LOADING, payload: true });
    try {
      const response = await httpService.get({
        action: API_ENDPOINT.premiumRequest,
        query: payload
      });
      return response.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(LOGIN, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function searchCandidate(payload) {
  return async function (dispatch) {
    dispatch({ type: SHOW_LOADING, payload: true });
    dispatch({ type: UPDATE_CANDIDATE_FILTERS, payload: payload });
    try {
      const response = await httpService.get({
        action: API_ENDPOINT.searchCandidate,
        query: payload
      });
      return response.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(LOGIN, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function taggedCandidate(payload) {
  return async function (dispatch) {
    dispatch({ type: SHOW_LOADING, payload: true });
    try {
      const response = await httpService.get({
        action: API_ENDPOINT.taggedCandidate,
        query: payload
      });
      return response.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(LOGIN, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function getRecruiterProfile() {
  return async function (dispatch) {
    dispatch({ type: SHOW_LOADING, payload: true });
    try {
      const response = await httpService.get({ action: API_ENDPOINT.recuriterProfile });
      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function updateProfile(payload) {
  return async function (dispatch) {
    dispatch({ type: SHOW_LOADING, payload: true });
    try {
      const response = await httpService.put({
        action: API_ENDPOINT.updateRecuriterProfile,
        data: payload
      });
      if (response?.data?.error) {
        dispatch({
          type: UPDATE_NOTIFICATION,
          payload: { variant: 'error', message: response.data.msg }
        });
        return;
      }

      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: { variant: 'success', message: response.data.msg }
      });

      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}
export function getSubUser(payload, id) {
  return async function (dispatch) {
    dispatch({ type: SHOW_LOADING, payload: true });
    try {
      const response = await httpService.get({
        action: API_ENDPOINT.subUser,
        segment: id,
        query: payload
      });
      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function viewJob(payload) {
  return async function (dispatch) {
    dispatch({ type: SHOW_LOADING, payload: true });
    try {
      const response = await httpService.get({ action: API_ENDPOINT.viewJob, query: payload });
      return response.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(LOGIN, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function viewRequest(payload) {
  return async function (dispatch) {
    dispatch({ type: SHOW_LOADING, payload: true });
    try {
      const response = await httpService.get({
        action: `${API_ENDPOINT.premiumRequest}/${payload}`
      });
      return response.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(LOGIN, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function addRequest(payload) {
  return async function (dispatch) {
    dispatch({ type: SHOW_LOADING, payload: true });
    try {
      const response = await httpService.get({
        action: API_ENDPOINT.addPremiumRequest,
        query: payload
      });
      return response.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(LOGIN, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function updateSubUser(id, payload) {
  return async function (dispatch) {
    try {
      dispatch({ type: SHOW_LOADING, payload: true });
      const response = await httpService.put({
        action: API_ENDPOINT.subUser + '/' + id,
        data: payload
      });
      if (response?.data?.error) {
        dispatch({
          type: UPDATE_NOTIFICATION,
          payload: { variant: 'error', message: response.data.msg }
        });
        return;
      }

      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: { variant: 'success', message: response.data.msg }
      });

      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function createSubUser(payload) {
  return async function (dispatch) {
    try {
      dispatch({ type: SHOW_LOADING, payload: true });
      const response = await httpService.post({ action: API_ENDPOINT.subUser, data: payload });
      if (response?.data?.error) {
        dispatch({
          type: UPDATE_NOTIFICATION,
          payload: { variant: 'error', message: response.data.msg }
        });
        return;
      }

      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: { variant: 'success', message: response.data.msg }
      });

      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function allocateSubUser(id, payload) {
  return async function (dispatch) {
    try {
      dispatch({ type: SHOW_LOADING, payload: true });
      const response = await httpService.put({
        action: API_ENDPOINT.allocate + '/' + id,
        data: payload
      });
      if (response?.data?.error) {
        dispatch({
          type: UPDATE_NOTIFICATION,
          payload: { variant: 'error', message: response.data.msg }
        });
        return;
      }

      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: { variant: 'success', message: response.data.msg }
      });

      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function uploadProfilePic(payload) {
  return async function (dispatch) {
    try {
      dispatch({ type: SHOW_LOADING, payload: true });
      const response = await httpService.post({
        action: API_ENDPOINT.uploadProfilePic,
        formData: payload
      });

      if (response?.data?.error) {
        dispatch({
          type: UPDATE_NOTIFICATION,
          payload: { variant: 'error', message: response.data.msg }
        });
        return;
      }

      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: { variant: 'success', message: response.data.msg }
      });

      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function deleteProfilePic() {
  return async function (dispatch) {
    try {
      dispatch({ type: SHOW_LOADING, payload: true });
      const response = await httpService.post({ action: API_ENDPOINT.deleteProfilePic });
      if (response?.data?.error) {
        dispatch({
          type: UPDATE_NOTIFICATION,
          payload: { variant: 'error', message: response.data.msg }
        });
        return;
      }

      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: { variant: 'success', message: response.data.msg }
      });

      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function deleteRecruiterVideo() {
  return async function (dispatch) {
    try {
      dispatch({ type: SHOW_LOADING, payload: true });
      const response = await httpService.delete({ action: API_ENDPOINT.deleteRecruiterVideo });
      if (response?.data?.error) {
        dispatch({
          type: UPDATE_NOTIFICATION,
          payload: { variant: 'error', message: response.data.msg }
        });
        return;
      }

      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: { variant: 'success', message: response.data.msg }
      });

      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function deleteCompanyLogo() {
  return async function (dispatch) {
    try {
      dispatch({ type: SHOW_LOADING, payload: true });
      const response = await httpService.delete({ action: API_ENDPOINT.deleteCompanyLogo });
      if (response?.data?.error) {
        dispatch({
          type: UPDATE_NOTIFICATION,
          payload: { variant: 'error', message: response.data.msg }
        });
        return;
      }

      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: { variant: 'success', message: response.data.msg }
      });

      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function deleteCompanyVideo() {
  return async function (dispatch) {
    try {
      dispatch({ type: SHOW_LOADING, payload: true });
      const response = await httpService.delete({ action: API_ENDPOINT.deleteCompanyVideo });
      if (response?.data?.error) {
        dispatch({
          type: UPDATE_NOTIFICATION,
          payload: { variant: 'error', message: response.data.msg }
        });
        return;
      }

      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: { variant: 'success', message: response.data.msg }
      });

      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function sendPremiumSearchQuery(id, payload) {
  return async function (dispatch) {
    try {
      dispatch({ type: SHOW_LOADING, payload: true });
      const response = await httpService.put({
        action: API_ENDPOINT.premiumRequest,
        segment: id,
        data: payload
      });
      if (response?.data?.error) {
        dispatch({
          type: UPDATE_NOTIFICATION,
          payload: { variant: 'error', message: response.data.msg }
        });
        return;
      }

      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: { variant: 'success', message: response.data.msg }
      });

      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function getPremiumPackData() {
  return async function (dispatch) {
    dispatch({ type: SHOW_LOADING, payload: true });
    try {
      const response = await httpService.get({ action: API_ENDPOINT.premiumPackData });
      return response.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(LOGIN, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function addPremiumRequest(payload) {
  return async function (dispatch) {
    dispatch({ type: SHOW_LOADING, payload: true });
    try {
      const response = await httpService.post({
        action: API_ENDPOINT.premiumRequest,
        formData: payload
      });
      if (response?.data.error) {
        dispatch({
          type: UPDATE_NOTIFICATION,
          payload: { variant: 'error', message: response.data.msg }
        });

        return;
      }

      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: { variant: 'success', message: response.data.msg }
      });

      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(LOGIN, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function postJob(payload) {
  return async function (dispatch) {
    try {
      dispatch({ type: SHOW_LOADING, payload: true });
      const response = await httpService.post({ action: API_ENDPOINT.postJob, data: payload });
      if (response?.data?.error) {
        dispatch({
          type: UPDATE_NOTIFICATION,
          payload: { variant: 'error', message: response.data.msg }
        });
        return;
      }

      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: { variant: 'success', message: response.data.msg }
      });

      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}
export function saveJobAsDraft(payload) {
  return async function (dispatch) {
    try {
      dispatch({ type: SHOW_LOADING, payload: true });
      const response = await httpService.post({ action: API_ENDPOINT.draftJob, data: payload });
      if (response?.data?.error) {
        dispatch({
          type: UPDATE_NOTIFICATION,
          payload: { variant: 'error', message: response.data.msg }
        });
        return;
      }

      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: { variant: 'success', message: response.data.msg }
      });

      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function fetchJob(payload) {
  return async function (dispatch) {
    try {
      dispatch({ type: SHOW_LOADING, payload: true });
      const response = await httpService.get({ action: `${API_ENDPOINT.fetchJob}/${payload}` });
      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function getPostJobUserList() {
  return async function (dispatch) {
    try {
      dispatch({ type: SHOW_LOADING, payload: true });
      const response = await httpService.get({ action: API_ENDPOINT.postJobUserList });
      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function changePassword(payload) {
  return async function (dispatch) {
    try {
      dispatch({ type: SHOW_LOADING, payload: true });
      const response = await httpService.post({
        action: API_ENDPOINT.changePassword,
        data: payload
      });
      if (response?.data?.error) {
        dispatch({
          type: UPDATE_NOTIFICATION,
          payload: { variant: 'error', message: response.data.msg }
        });
        return;
      }

      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: { variant: 'success', message: response.data.msg }
      });

      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function getSavedCandidate(payload) {
  return async function (dispatch) {
    try {
      dispatch({ type: SHOW_LOADING, payload: true });
      const response = await httpService.get({
        action: API_ENDPOINT.savedCandidate,
        query: payload
      });

      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function saveCandidate(payload) {
  return async function (dispatch) {
    try {
      dispatch({ type: SHOW_LOADING, payload: true });
      const response = await httpService.put({
        action: API_ENDPOINT.savedCandidate,
        data: payload
      });

      if (response?.data?.error) {
        dispatch({
          type: UPDATE_NOTIFICATION,
          payload: { variant: 'error', message: response.data.msg }
        });
        return;
      }

      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: {
          variant: 'success',
          message: `Candidate ${response.data.msg === 'add' ? 'saved' : 'unsaved'} successfully`
        }
      });

      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function getDownloadCandidate(payload) {
  return async function (dispatch) {
    try {
      dispatch({ type: SHOW_LOADING, payload: true });
      const response = await httpService.get({
        action: API_ENDPOINT.downloadCandidate,
        query: payload
      });
      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function getShortListedCandidate(payload) {
  return async function (dispatch) {
    try {
      dispatch({ type: SHOW_LOADING, payload: true });
      const response = await httpService.get({ action: API_ENDPOINT.shortListed, query: payload });
      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function getApplications(id, payload) {
  return async function (dispatch) {
    try {
      dispatch({ type: SHOW_LOADING, payload: true });
      const response = await httpService.get({
        action: API_ENDPOINT.application,
        segment: id,
        query: payload
      });
      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function updateApplications(payload) {
  return async function (dispatch) {
    try {
      dispatch({ type: SHOW_LOADING, payload: true });
      const response = await httpService.put({ action: API_ENDPOINT.application, data: payload });
      if (response?.data.error) {
        dispatch({
          type: UPDATE_NOTIFICATION,
          payload: { variant: 'error', message: response.data.msg }
        });
        return;
      }

      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: { variant: 'success', message: response.data.msg }
      });

      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function getInterviewList(payload) {
  return async function (dispatch) {
    try {
      dispatch({ type: SHOW_LOADING, payload: true });
      const response = await httpService.get({ action: API_ENDPOINT.interview, query: payload });
      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function getInterviewDetails(id) {
  return async function (dispatch) {
    try {
      dispatch({ type: SHOW_LOADING, payload: true });
      const response = await httpService.get({
        action: API_ENDPOINT.interviewDetails,
        segment: id
      });
      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function getCandidateInterviewDetails(payload) {
  return async function (dispatch) {
    try {
      dispatch({ type: SHOW_LOADING, payload: true });
      const response = await httpService.get({
        action: API_ENDPOINT.getCandidateInfoInterview,
        query: payload
      });
      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function addInterview(payload) {
  return async function (dispatch) {
    try {
      dispatch({ type: SHOW_LOADING, payload: true });
      const response = await httpService.post({ action: API_ENDPOINT.interview, data: payload });
      if (response?.data?.error) {
        if (response?.data?.errorCode !== 'email') {
          dispatch({
            type: UPDATE_NOTIFICATION,
            payload: { variant: 'error', message: response.data.msg }
          });
        }
        return response?.data;
      }

      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: { variant: 'success', message: response.data.msg }
      });

      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function updateInterview(id, payload) {
  return async function (dispatch) {
    try {
      dispatch({ type: SHOW_LOADING, payload: true });
      const response = await httpService.put({
        action: `${API_ENDPOINT.interview}/${id}`,
        data: payload
      });
      if (response?.data?.error) {
        dispatch({
          type: UPDATE_NOTIFICATION,
          payload: { variant: 'error', message: response.data.msg }
        });
        return;
      }

      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: { variant: 'success', message: response.data.msg }
      });

      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function getPositionList() {
  return async function (dispatch) {
    try {
      dispatch({ type: SHOW_LOADING, payload: true });
      const response = await httpService.get({ action: API_ENDPOINT.positionList });
      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function getInterviewStatusList() {
  return async function (dispatch) {
    try {
      dispatch({ type: SHOW_LOADING, payload: true });
      const response = await httpService.get({ action: API_ENDPOINT.interviewStatusList });
      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function getCompanyProfile() {
  return async function (dispatch) {
    dispatch({ type: SHOW_LOADING, payload: true });
    try {
      const response = await httpService.get({ action: API_ENDPOINT.fetchCompany });
      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function updateCompanyProfile(payload) {
  return async function (dispatch) {
    dispatch({ type: SHOW_LOADING, payload: true });
    try {
      const response = await httpService.put({ action: API_ENDPOINT.updatecompany, data: payload });
      if (response?.data?.error) {
        dispatch({
          type: UPDATE_NOTIFICATION,
          payload: { variant: 'error', message: response.data.msg }
        });
        return;
      }

      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: { variant: 'success', message: response.data.msg }
      });

      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function uploadCompanyProfilePic(payload) {
  return async function (dispatch) {
    try {
      dispatch({ type: SHOW_LOADING, payload: true });
      const response = await httpService.post({
        action: API_ENDPOINT.updatecompanylogo,
        formData: payload
      });

      if (response?.data?.error) {
        dispatch({
          type: UPDATE_NOTIFICATION,
          payload: { variant: 'error', message: response.data.msg }
        });
        return;
      }

      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: { variant: 'success', message: response.data.msg }
      });

      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function getChatList() {
  return async function (dispatch) {
    dispatch({ type: SHOW_LOADING, payload: true });
    try {
      const response = await httpService.get({ action: API_ENDPOINT.fetchChatList });
      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function getChat(query) {
  return async function (dispatch) {
    try {
      const response = await httpService.get({ action: API_ENDPOINT.fetchChat, query: query });
      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    }
  };
}

export function sendChatMessage(payload) {
  return async function (dispatch) {
    try {
      const response = await httpService.put({
        action: API_ENDPOINT.sendChatMessage,
        data: payload
      });
      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    }
  };
}

export function getCandidateDetails(payload) {
  return async function (dispatch) {
    try {
      dispatch({ type: SHOW_LOADING, payload: true });
      const response = await httpService.get({
        action: API_ENDPOINT.candidateDetails,
        query: payload
      });
      if (response?.data?.error) {
        dispatch({
          type: UPDATE_NOTIFICATION,
          payload: { variant: 'error', message: response.data.msg }
        });

        if (
          response.data.msg ===
          "You don't have any subscription, Please subscribe a plan to Post Job & get access to Resumes"
        )
          return { subscription: false };

        return;
      }

      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function updateJobStatus(id, payload) {
  return async function (dispatch) {
    try {
      dispatch({ type: SHOW_LOADING, payload: true });
      const response = await httpService.put({
        action: API_ENDPOINT.updateJobStatus,
        segment: id,
        data: payload
      });
      if (response?.data?.error) {
        dispatch({
          type: UPDATE_NOTIFICATION,
          payload: { variant: 'error', message: response.data.msg }
        });
        return;
      }

      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: { variant: 'success', message: response.data.msg }
      });

      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function getTags(id) {
  return async function (dispatch) {
    try {
      dispatch({ type: SHOW_LOADING, payload: true });
      const response = await httpService.get({ action: API_ENDPOINT.tags, segment: id });
      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function addTag(data) {
  return async function (dispatch) {
    try {
      dispatch({ type: SHOW_LOADING, payload: true });
      const response = await httpService.post({ action: API_ENDPOINT.tags, data });
      if (response?.data?.error) {
        dispatch({
          type: UPDATE_NOTIFICATION,
          payload: { variant: 'error', message: response.data.msg }
        });
        return;
      }

      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: { variant: 'success', message: response.data.msg }
      });

      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function removeTag(id) {
  return async function (dispatch) {
    try {
      dispatch({ type: SHOW_LOADING, payload: true });
      const response = await httpService.delete({ action: API_ENDPOINT.tags, segment: id });
      if (response?.data.error) {
        dispatch({
          type: UPDATE_NOTIFICATION,
          payload: { variant: 'error', message: response.data.msg }
        });

        return;
      }

      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: { variant: 'success', message: response.data.msg }
      });

      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function getSubscription() {
  return async function (dispatch) {
    try {
      dispatch({ type: SHOW_LOADING, payload: true });
      const response = await httpService.get({ action: API_ENDPOINT.subscription });
      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function moveJobToActive(payload) {
  return async function (dispatch) {
    try {
      dispatch({ type: SHOW_LOADING, payload: true });
      const response = await httpService.post({ action: API_ENDPOINT.moveToActive, data: payload });
      if (response?.data?.error) {
        dispatch({
          type: UPDATE_NOTIFICATION,
          payload: { variant: 'error', message: response.data.msg }
        });
        return;
      }

      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: { variant: 'success', message: response.data.msg }
      });

      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function updateRecruiterVideo(payload) {
  return async function (dispatch) {
    try {
      const response = await httpService.put({
        action: API_ENDPOINT.updateRecruiterVideo,
        data: payload
      });
      if (response?.data.error) {
        dispatch({
          type: UPDATE_NOTIFICATION,
          payload: { variant: 'error', message: response.data.msg }
        });

        return;
      }

      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: { variant: 'success', message: response.data.msg }
      });

      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    }
  };
}

export function updateCompanyVideo(payload) {
  return async function (dispatch) {
    try {
      const response = await httpService.put({
        action: API_ENDPOINT.updateCompanyVideo,
        data: payload
      });
      if (response?.data.error) {
        dispatch({
          type: UPDATE_NOTIFICATION,
          payload: { variant: 'error', message: response.data.msg }
        });

        return;
      }

      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: { variant: 'success', message: response.data.msg }
      });

      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    }
  };
}

export function getCandidateReview(id) {
  return async function (dispatch) {
    try {
      dispatch({ type: SHOW_LOADING, payload: true });
      const response = await httpService.get({ action: `${API_ENDPOINT.candidateReview}/${id}` });
      if (response?.data.error) {
        dispatch({
          type: UPDATE_NOTIFICATION,
          payload: { variant: 'error', message: response.data.msg }
        });
        return;
      }
      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function getEditCandidateReview(id) {
  return async function (dispatch) {
    try {
      dispatch({ type: SHOW_LOADING, payload: true });
      const response = await httpService.get({
        action: `${API_ENDPOINT.candidateReview}/${id}/edit`
      });
      if (response?.data.error) {
        dispatch({
          type: UPDATE_NOTIFICATION,
          payload: { variant: 'error', message: response.data.msg }
        });
        return;
      }
      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function updateCandidateReview(id, payload) {
  return async function (dispatch) {
    try {
      dispatch({ type: SHOW_LOADING, payload: true });
      const response = await httpService.put({
        action: `${API_ENDPOINT.candidateReview}/${id}`,
        data: payload
      });
      if (response?.data.error) {
        dispatch({
          type: UPDATE_NOTIFICATION,
          payload: { variant: 'error', message: response.data.msg }
        });
        return;
      }
      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

/*export function removeSubuser(id, payload) {
    
    return async function (dispatch, payload) {
      
        try { 
            console.log(`${API_ENDPOINT.deletesubUser}/${id}`);
        
            dispatch({ type: SHOW_LOADING, payload: true });
           //const response = await httpService.delete({ action: API_ENDPOINT.SubUser + '/' + id});
           const response = await httpService.delete({ action: `${API_ENDPOINT.deletesubUser}/${id}`, data: payload });
        
            if (response?.data?.error) {
                dispatch({
                    type: UPDATE_NOTIFICATION,
                    payload: { variant: 'error', message: response.data.msg },
                });
                return;
            }
    
            dispatch({
                type: UPDATE_NOTIFICATION,
                payload: { variant: 'success', message: response.data.msg },
            });
    
            return response?.data;
        } catch (err) {
            if (err.statusCode === 409) throw err;
            dispatch(handleError(DEFAULT_ACTION, err));
        }
        finally {
            dispatch({ type: SHOW_LOADING, payload: false });
        }
};
}*/

export function removeSubuser(id, payload) {
  return async function (dispatch) {
    try {
      dispatch({ type: SHOW_LOADING, payload: true });
      const response = await httpService.delete({
        action: `${API_ENDPOINT.deletesubUsers}/${id}`,
        data: payload
      });
      if (response?.data?.error) {
        dispatch({
          type: UPDATE_NOTIFICATION,
          payload: { variant: 'error', message: response.data.msg }
        });
        return;
      }

      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: { variant: 'success', message: response.data.msg }
      });

      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function markAsReadMSG(payload) {
  return async function (dispatch) {
    try {
      const response = await httpService.post({
        action: API_ENDPOINT.markAsReadMSG,
        data: payload
      });
      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function markResumeAsView(payload) {
  return async function (dispatch) {
    try {
      dispatch({ type: SHOW_LOADING, payload: true });
      const response = await httpService.get({
        action: API_ENDPOINT.downloadResumeUpdate,
        query: payload
      });
      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

/**  Draft Job */

export function getDraftJob(payload) {
  return async function (dispatch) {
    try {
      dispatch({ type: SHOW_LOADING, payload: true });
      const response = await httpService.get({
        action: API_ENDPOINT.getDraftJob,
        query: payload
      });
      if (response?.data?.error) {
        dispatch({
          type: UPDATE_NOTIFICATION,
          payload: { variant: 'error', message: response.data.msg }
        });
        return;
      }
      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function deleteDraftJob(payload) {
  return async function (dispatch) {
    try {
      dispatch({ type: SHOW_LOADING, payload: true });
      const response = await httpService.delete({
        action: API_ENDPOINT.deleteDraftJob,
        data: payload
      });
      if (response?.data?.error) {
        dispatch({
          type: UPDATE_NOTIFICATION,
          payload: { variant: 'error', message: response.data.msg }
        });
        return;
      }

      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: { variant: 'success', message: response.data.msg }
      });

      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}
