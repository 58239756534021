import React from 'react';
import Header from "./shared/header";

const RestrictedAccess = () => {
  return (
    <div>
	  <section className="py-5 employer-restriction">
             <div className="container">
                 <div className="row">
                   <div className="col-lg-8 mx-auto">
                        <div className="text-center">
                            <h1 className="fw-bold lh-base text-capitalize">WE’RE SORRY.</h1>
                            <p className="text-secondary mb-4 lh-base sub-content">VeriiPro is not available in your country yet. </p>
                     
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
	
  );
};

export default RestrictedAccess;