import {
    DEFAULT_ACTION, FORGOT_PASSWORD, LOGIN, LOG_OUT,
    REQUEST_OTP, RESEND_OTP, VERIFY_OTP, RESET_PASSWORD, CHANGE_PASSWORD, FORGET_VERIFY_OTP,CONTACT_US
} from "./action-types";

export const NOTIFICATION_MESSAGES = Object.freeze({
    [DEFAULT_ACTION]: {
        error: {
            title: "Error",
            variant: "error",
            message: "Something went wrong!",
        },
    },
    [LOGIN]: {
        success: {
            title: "Login",
            variant: "success",
            message: "Logged in successfully!",
        },
        error: {
            title: "Login",
            variant: "error",
            message: "Something went wrong!",
        }
    },
    [CONTACT_US]: {
        success: {
            title: "Form sent",
            variant: "success",
            message: "Form Submit Successfully!",
        },
        error: {
            title: "Sign up",
            variant: "error",
            message: "Something went wrong!",
        },
    },
    [LOG_OUT]: {
        title: 'Logout',
        message: 'You have been logged out',
        variant: 'info'
    },
    [FORGOT_PASSWORD]: {
        error: {
            title: "Forgot Password",
            variant: "error",
            message: "Something went wrong!",
        },
    },
    [REQUEST_OTP]: {
        success: {
            title: "OTP sent",
            variant: "success",
            message: "OTP sent successfully!",
        },
        error: {
            title: "Sign up",
            variant: "error",
            message: "Something went wrong!",
        },
    },
    [RESEND_OTP]: {
        success: {
            title: "OTP request",
            variant: "success",
            message: "OTP sent successfully!",
        },
        error: {
            title: "OTP request",
            variant: "error",
            message: "Something went wrong!",
        },
    },
    [VERIFY_OTP]: {
        success: {
            title: "Sign up",
            variant: "success",
            message: "Signed-up successfully!",
        },
        error: {
            title: "Sign up",
            variant: "error",
            message: "Invalid OTP!",
        },
    },
    [FORGET_VERIFY_OTP]: {
        success: {
            title: "OTP Verified",
            variant: "success",
            message: "OTP verified successfully!",
        },
    },
    [RESET_PASSWORD]: {
        success: {
            title: "Password Reset",
            variant: "success",
            message: "Password changed successfully!",
        },
        error: {
            title: "Password Reset",
            variant: "error",
            message: "Something went wrong!",
        },
    },
    [CHANGE_PASSWORD]: {
        success: {
            title: "Change Password",
            variant: "success",
            message: "Password changed successfully!",
        },
        error: {
            title: "Change Password",
            variant: "error",
            message: "Something went wrong!",
        },
    }
});
