import { useRoutes } from 'react-router-dom';
import { lazyLoadRoutes } from '../utils/lazy-loader';
import routes from '../constants/routes';

export function AppRoutes() {
  const appRoutes = [
    {
      path: '/',
      children: [
        {
          index: true,
          element: lazyLoadRoutes('home')
        },
        {
          path: routes.login,
          element: lazyLoadRoutes('auth/login')
        },
        /*  {
                    path: routes.register,
                    element: lazyLoadRoutes('auth/register'),
                },
                {
                    path: routes.contactUs,
                    element: lazyLoadRoutes('auth/contact-us'),
                },*/
        {
          path: routes.thankYou,
          element: lazyLoadRoutes('auth/thank-you')
        },
        {
          path: routes.registerOtpVerify,
          element: lazyLoadRoutes('auth/register-otp-verify')
        },
        {
          path: routes.resetPassword,
          element: lazyLoadRoutes('auth/reset-password')
        },
        {
          path: routes.forgotPassword,
          element: lazyLoadRoutes('auth/forgot-password')
        },
        {
          path: routes.dashboard,
          element: lazyLoadRoutes('Dashboard', true)
        },
        {
          path: routes.candidateSearchHistory,
          element: lazyLoadRoutes('candidate/CandidateSearchHistory', true)
        },
        {
          path: routes.changePassword,
          element: lazyLoadRoutes('profile/change-password', true)
        },
        {
          path: routes.taggedCandidate,
          element: lazyLoadRoutes('candidate/tagged', true)
        },
        {
          //path: `${routes.candidateProfile}/:id/`,
          path: `${routes.candidateProfile}/:id/:job_id`,
          element: lazyLoadRoutes('candidate/profile', true)
        },
        {
          path: routes.downloadedCandidate,
          element: lazyLoadRoutes('candidate/downloaded', true)
        },
        {
          path: routes.savedCandidate,
          element: lazyLoadRoutes('candidate/saved', true)
        },
        // {
        //   path: routes.applications,
        //   element: lazyLoadRoutes('candidate/applications', true)
        // },
        {
          path: `${routes.applications}/:jobId`,
          element: lazyLoadRoutes('candidate/applications-job', true)
        },
        // {
        //   path: routes.shortListedCandidate,
        //   element: lazyLoadRoutes('candidate/short-listed', true)
        // },
        // {
        //   path: `${routes.shortListedCandidate}/:jobId`,
        //   element: lazyLoadRoutes('candidate/short-listed-job', true)
        // },
        {
          path: routes.candidateSearch,
          element: lazyLoadRoutes('candidate/search-candidate', true)
        },
        {
          path: routes.candidateSearch,
          element: lazyLoadRoutes('candidate/search-candidate', true)
        },
        {
          path: routes.searchCandidateResult,
          element: lazyLoadRoutes('candidate/search-candidate-result', true)
        },
        // {
        //   path: routes.orderHistory,
        //   element: lazyLoadRoutes('profile/order-history', true)
        // },
        // {
        //   path: routes.allocationHistory,
        //   element: lazyLoadRoutes('profile/allocation-history', true)
        // },
        {
          path: `${routes.jobs}:type`,
          element: lazyLoadRoutes('jobs/job-list', true)
        },
        {
          path: routes.postJob,
          element: lazyLoadRoutes('jobs/post-job', true)
        },
        {
          path: routes.draftJob,
          element: lazyLoadRoutes('jobs/draft-job', true)
        },
        {
          path: `${routes.postJob}/:id`,
          element: lazyLoadRoutes('jobs/post-job', true)
        },
        {
          path: `${routes.viewJob}/:slug`,
          element: lazyLoadRoutes('jobs/view-job', true)
        },
        {
          path: routes.premiumRequest,
          element: lazyLoadRoutes('candidate/premium-request', true)
        },
        {
          path: routes.addPremiumRequest,
          element: lazyLoadRoutes('candidate/add-premium-request', true)
        },
        {
          path: `${routes.viewPremiumRequest}/:id`,
          element: lazyLoadRoutes('candidate/view-premium-request', true)
        },
        // {
        //   path: routes.manageSubUser,
        //   element: lazyLoadRoutes('profile/manage-sub-user', true)
        // },
        // {
        //   path: routes.addSubUser,
        //   element: lazyLoadRoutes('profile/add-sub-user', true)
        // },
        // {
        //   path: `${routes.updateSubUser}/:id`,
        //   element: lazyLoadRoutes('profile/update-sub-user', true)
        // },
        // {
        //   path: `${routes.editInterview}/:id`,
        //   element: lazyLoadRoutes('interviews/edit', true)
        // },
        // {
        //     path: routes.interview,
        //     element: lazyLoadRoutes('interviews/manage', true),
        // },
        // {
        //   path: routes.addInterview,
        //   element: lazyLoadRoutes('interviews/add', true)
        // },
        // {
        //   path: `${routes.addInterview}/:cvId/`,
        //   element: lazyLoadRoutes('interviews/add:jobId', true)
        // },
        // {
        //   path: `${routes.addInterview}/:cvId/:jobId`,
        //   element: lazyLoadRoutes('interviews/add', true)
        // },
        // {
        //   path: `${routes.editInterview}/:id`,
        //   element: lazyLoadRoutes('interviews/edit', true)
        // },
        {
          path: routes.updateCompanyProfile,
          element: lazyLoadRoutes('profile/company-profile', true)
        },
        {
          path: routes.viewMessages,
          element: lazyLoadRoutes('profile/view-messages', true)
        },
        {
          path: routes.updateProfile,
          element: lazyLoadRoutes('profile/update-profile', true)
        },
        // {
        //   path: routes.subscriptionHistory,
        //   element: lazyLoadRoutes('profile/subscription', true)
        // },
        {
          path: `${routes.candidateReview}/:id`,
          element: lazyLoadRoutes('candidate/candidate-review', true)
        },
        {
          path: `${routes.addCandidateReview}/:id`,
          element: lazyLoadRoutes('candidate/add-candidate-review', true)
        },
        {
          path: routes.notFound,
          element: lazyLoadRoutes('not-found')
        }
      ]
    },
    {
      path: '*',
      element: lazyLoadRoutes('not-found')
    }
  ];

  return useRoutes(appRoutes);
}
