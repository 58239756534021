export const logo = require('../assets/img/veriipro-logo.svg').default;
export const googlePlay = require('../assets/img/google-play.png');
export const appStore = require('../assets/img/appstore.png');
export const user = require('../assets/img/user.png');
export const searchIcon = require('../assets/img/search-icon.svg').default;
export const mapMarkerLine = require('../assets/img/map-marker-line.svg').default;
export const android = require('../assets/img/android.png');
export const apple = require('../assets/img/apple.png');
export const arrowLeftIcon = require('../assets/img/arrow-left-icon.svg').default;
export const arrowLeft = require('../assets/img/arrow-left.svg').default;
export const chevronDown = require('../assets/img/chevron-down.svg').default;
export const chevronRight = require('../assets/img/chevron-right.svg').default;
export const companyIcon = require('../assets/img/company-icon.svg').default;
export const experienceIcon = require('../assets/img/experience.svg').default;
export const dotNet = require('../assets/img/dot-net.png');
export const downArrow = require('../assets/img/down-arrow.png');
export const dropArrow = require('../assets/img/drop-arrow.svg').default;
export const eye = require('../assets/img/eye.svg').default;
export const forgotImg = require('../assets/img/forgot-img.png');
export const headerBg = require('../assets/img/header-bg.jpg');
export const html5 = require('../assets/img/html-5.png');
export const java = require('../assets/img/java.png');
export const locationIcon = require('../assets/img/loction-icon.svg').default;
export const authorization = require('../assets/img/authorization.svg').default;
export const loction = require('../assets/img/loction.svg').default;
export const loginImg = require('../assets/img/login-img.svg').default;
export const officeChair = require('../assets/img/officechair.svg').default;
export const php = require('../assets/img/php.png');
export const react = require('../assets/img/react.png');
export const signupImg = require('../assets/img/signup-img.svg').default;
export const skillsIcon = require('../assets/img/skills-icon.svg').default;
export const jobTyopeIcon = require('../assets/img/jobtype-icon.svg').default;
export const upload = require('../assets/img/upload.svg').default;
export const uxInterface = require('../assets/img/ux-interface.png');
export const verifyingCandidateProfile =
  require('../assets/img/verifying-candidate-profile.svg').default;
export const walletIcon = require('../assets/img/wallet-icon.svg').default;
export const candidateLoginImg = require('../assets/img/login-img-cand.png');
export const NotFoundImage = require('../assets/img/bg-404.png');
export const travelRequirements = require('../assets/img/Travel-Height.svg').default;
export const fillTechImg = require('../assets/img/fill-tech.svg').default;
export const accessImg = require('../assets/img/Access.svg').default;
export const qualifiedImg = require('../assets/img/qualified.svg').default;
export const greenRight = require('../assets/img/greenright.svg').default;
export const searchTalent = require('../assets/img/searchtalent.svg').default;
export const postJob = require('../assets/img/postjob.svg').default;
export const MessageIconBlack = require('../assets/img/message-icon-black.png');
export const MessageIconBlue = require('../assets/img/message-icon-blue.png');
export const postJobIcon = require('../assets/img/postJobIcon.svg').default;
export const manageJobIcon = require('../assets/img/ManageJobs.svg').default;
export const savedJobIcon = require('../assets/img/SavedCandidate.svg').default;
export const candidateNotes = require('../assets/img/CandidateNotes.svg').default;
export const downloadedCandidate = require('../assets/img/DownloadedCandidates.svg').default;
export const educationIcon = require('../assets/img/education.svg').default;
