import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Avatar from 'react-avatar';
import { updateSelectedCandidate } from '../../store/global/actions';
import { getChat, sendChatMessage } from '../../store/employer/actions';
import { formatDate } from '../../utils/utility';

function SendMessage(props) {
  const { selectedCandidate, updateSelectedCandidate, getChat, sendChatMessage, currentUser } =
    props;
  const [chatMessages, setChatMessages] = useState([]);
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);
  const chatRef = useRef();

  useEffect(() => {
    if (selectedCandidate?.type === 'messages') getChats();
  }, [selectedCandidate]);

  const getChats = async () => {
    const { data } = await getChat({ cv_id: selectedCandidate.id });
    setChatMessages(data);
    scrollToBottom();
  };

  const onSendMessage = async () => {
    if (!message) {
      setError(true);
      return;
    }

    setError(false);
    const { error } = await sendChatMessage({
      cv_id: selectedCandidate.id,
      msg: message
    });

    if (!error) {
      setMessage('');
      await getChats();
    }
  };

  const scrollToBottom = () => {
    window.setTimeout(() => {
      chatRef.current?.scrollIntoView(false, { behavior: 'smooth' });
    }, 100);
  };

  const handleClose = () => {
    updateSelectedCandidate(null);
  };

  return (
    <>
      {selectedCandidate?.type === 'messages' && (
        <div className="position-fixed small-chat bottom-0">
          <div className="card rounded-4 overflow-hidden">
            <div className="card-header">
              <ul className="list-style-none p-0 m-0 d-flex justify-content-between">
                <li className="d-flex align-items-center ">
                  <Avatar
                    size="50"
                    round
                    name={selectedCandidate?.details?.name}
                    src={selectedCandidate?.details?.image}
                    className="object-fit-cover rounded-pill me-3"
                  />
                  <div>
                    <h6 className="mb-0 lh-base fw-600 text-dark text-opacity-75 text-capitalize">
                      {selectedCandidate?.details.name}
                    </h6>
                    {selectedCandidate?.details?.jobTitle && (
                      <div className="fs-14 lh-base text-dark text-opacity-75 text-capitalize">
                        {selectedCandidate?.details.jobTitle}
                      </div>
                    )}
                    {selectedCandidate?.details?.location && (
                      <p className="fs-14 lh-base text-secondary fw-500 text-capitalize mb-0">
                        {selectedCandidate?.details?.location}
                      </p>
                    )}
                  </div>
                </li>
                <li>
                  <button
                    type="button"
                    className="border-0 bg-transparent d-flex p-1"
                    onClick={handleClose}>
                    <i className="fa fa-close fs-4 text-secondary"></i>
                  </button>
                </li>
              </ul>
            </div>
            <div className="card-body scroll">
              {chatMessages.length > 0 ? (
                _.map(chatMessages, (item, i) => (
                  <div key={i} ref={chatRef}>
                    {item.sender === 'C' && (
                      <ul className="p-0 m-0 d-flex list-style-none justify-content-start mb-3">
                        <li className="d-flex">
                          <div className="jd-comment-pic">
                            <Avatar
                              size="50"
                              round
                              name={selectedCandidate?.details?.name}
                              src={selectedCandidate?.details?.image}
                              className="object-fit-cover rounded-pill border"
                            />
                          </div>
                          <div className="ms-3">
                            <span className="text-white py-1 px-2 bg-secondary fw-500 bg-opacity-50 rounded-top rounded-end fs-14">
                              {item.msg_text}
                            </span>
                            <p className="mb-0 lh-base fs-12 text-secondary mt-2">
                              {formatDate(item.created_at, true)}
                            </p>
                          </div>
                        </li>
                      </ul>
                    )}
                    {item.sender === 'E' && (
                      <ul className="p-0 d-flex list-style-none justify-content-end mb-3">
                        <li className="d-flex text-end">
                          <div className="me-3">
                            <span className="text-secondary py-1 bg-white lh-base px-2 fw-500 rounded-top rounded-start fs-14">
                              {item.msg_text}
                            </span>
                            <p className="mb-0 lh-base fs-12 text-secondary mt-2">
                              {formatDate(item.created_at, true)}
                            </p>
                          </div>
                          <div className="jd-comment-pic">
                            <Avatar
                              size="50"
                              round
                              name={currentUser?.contact_person}
                              className="object-fit-cover rounded-pill border"
                            />
                          </div>
                        </li>
                      </ul>
                    )}
                  </div>
                ))
              ) : (
                <p className="text-center mb-0 lh-base text-secondary">No Record Found</p>
              )}
            </div>

            <div className="bg-light p-3">
              <textarea
                className="form-control rounded-3 p-3 shadow-none bg-secondary bg-opacity-10 border-0 text-dark mb-1 scroll"
                rows="2"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Message"
              />
              {error && <span className="invalid-feedback d-block">Please enter your message</span>}
              <div className="text-end">
                <button
                  className="text-capitalize btn btn-primary rounded-pill bg-blue boreder px-3 py-1 fw-500"
                  type="submit"
                  onClick={onSendMessage}>
                  send
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default connect(
  (state) => ({
    selectedCandidate: state.global.selectedCandidate,
    currentUser: state.auth.currentUser
  }),
  { updateSelectedCandidate, getChat, sendChatMessage }
)(SendMessage);
