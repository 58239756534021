export function maskNumber(input) {
  return input?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export function maskPhoneNumber(value) {
  if (!value) return null;

  var x = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  return !x[2] ? x[1] : '' + x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '');
}

export function unmaskPhoneNumber(maskedValue) {
  if (!maskedValue) return null;

  return maskedValue.replace(/\D/g, '');
}

export const maskCurrency = (input) => {
  input = input.toString();
  if (input) {
    let value = input.replace(/\D/g, '');
    value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return value;
  }
  return input;
};

export const unmaskCurrency = (maskedValue) => {
  if (maskedValue) {
    const digitsOnly = maskedValue.replace(/\D/g, '');
    const originalNumber = parseFloat(digitsOnly);
    return originalNumber;
  } else {
    return '';
  }
};
