import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const CustomToolTip = ({ text, children, placement = 'top' }) => {
  return (
    <OverlayTrigger
      delay={{ hide: 450, show: 300 }}
      overlay={(props) => <Tooltip {...props}>{text}</Tooltip>}
      placement={placement}>
      {children}
    </OverlayTrigger>
  );
};

export default CustomToolTip;
