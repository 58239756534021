import _ from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import routes from '../../constants/routes';
import { logo } from '../../constants/images';
import { SOCIAL_MEDIA } from '../../constants/constants';
export default function CommanFooter() {
  return (
    <div className="footer py-3 text-center text-sm-start">
      <footer>
        <div className="container">
          <div className="row row-cols-1 row-cols-sm-2 row-cols-xl-5 row-cols-lg-4 row-cols-md-3">
            <div className="col my-3">
              <Link to={routes.commanFooter.home}>
                <img src={logo} alt="VeriiPro-logo" className="logo pe-none" />
              </Link>
              {/* <div className="footer-link">
                                <ul className="p-0 m-0 mt-3 list-style-none">
                                    <li className="my-3">
                                        <a href="https://play.google.com/store/apps/details?id=com.veriipro.jobsearch" target="_blank" rel="noreferrer"><img src={googlePlay.src} alt="Play Store" width={119} height={35}/></a>
                                    </li>
                                    <li className="my-3">
                                        <a href="https://apps.apple.com/us/app/veriipro-job-search/id1479504997" target="_blank"><img src={appStore.src} alt="App Store" width={119} height={35} /></a>
                                    </li>
                                </ul>
                            </div>*/}

              <div className="footer-link foot-ipad-show">
                <h6 className="mb-4 text-capitalize fw-600">Connect With Us</h6>
                <ul className="p-0 m-0 mt-3 list-style-none">
                  <li className="my-2">
                    <a
                      href={SOCIAL_MEDIA.LINKEDIN}
                      target="_blank"
                      className="text-decoration-none text-secondary text-capitalize fs-14"
                      rel="noreferrer">
                      <i className="fa-brands fa-linkedin me-2 footer-icon"></i>
                    </a>
                    <a
                      href={SOCIAL_MEDIA.TWITTER}
                      target="_blank"
                      className="text-decoration-none text-secondary text-capitalize fs-14"
                      rel="noreferrer">
                      <i className="fa-custom-icon fa-x-twitter fa-fw me-2 footer-icon"></i>
                    </a>
                    <a
                      href={SOCIAL_MEDIA.FACEBOOK}
                      target="_blank"
                      className="text-decoration-none text-secondary text-capitalize fs-14"
                      rel="noreferrer">
                      <i className="fa-brands fa-facebook me-2 footer-icon"></i>
                    </a>
                    <a
                      href={SOCIAL_MEDIA.PINTREST}
                      target="_blank"
                      className="text-decoration-none text-secondary text-capitalize fs-14"
                      rel="noreferrer">
                      <i className="fa-brands fa-pinterest footer-icon"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col my-3">
              <h6 className="mb-4 text-capitalize fw-600">For Candidates</h6>
              <div className="footer-link">
                <ul className="p-0 m-0 list-style-none">
                  <li className="my-2">
                    <Link
                      className="text-decoration-none text-secondary text-capitalize fs-14"
                      to={routes.candidateLogin}>
                      login
                    </Link>
                  </li>
                  <li className="my-2">
                    <Link
                      className="text-decoration-none text-secondary text-capitalize fs-14"
                      to={routes.candidateRegister}>
                      sign up
                    </Link>
                  </li>
                  <li className="my-2">
                    <Link
                      to={routes.webJobs}
                      className="text-decoration-none text-secondary text-capitalize fs-14">
                      Browse Jobs
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col my-3">
              <h6 className="mb-4 text-capitalize fw-600">For Employers</h6>
              <div className="footer-link">
                <ul className="p-0 m-0 list-style-none">
                  <li className="my-2">
                    <Link
                      className="text-decoration-none text-secondary text-capitalize fs-14"
                      to={routes.login}>
                      Login
                    </Link>
                  </li>
                  <li className="my-2">
                    <Link className="text-decoration-none text-secondary  fs-14" to={routes.home}>
                      Post a Job
                    </Link>
                  </li>
                  <li className="my-2">
                    <Link
                      className="text-decoration-none text-secondary text-capitalize fs-14"
                      to={routes.home}>
                      Register Account
                    </Link>
                  </li>
                  <li className="my-2">
                    <Link
                      className="text-decoration-none text-secondary text-capitalize fs-14"
                      to={routes.home}>
                      Search Candidates
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col my-3">
              <h6 className="mb-4 text-capitalize fw-600">Useful Links</h6>
              <div className="footer-link">
                <ul className="p-0 m-0 list-style-none">
                  <li className="my-2">
                    <Link
                      to={routes.blog}
                      className="text-decoration-none text-secondary text-capitalize fs-14">
                      Blog
                    </Link>
                  </li>
                  <li className="my-2">
                    <Link
                      to={routes.commanFooter.contact}
                      className="text-decoration-none text-secondary text-capitalize fs-14">
                      Contact Us
                    </Link>
                  </li>
                  <li className="my-2">
                    <Link
                      to={routes.termsOfService}
                      className="text-decoration-none text-secondary text-capitalize fs-14">
                      Terms and Conditions
                    </Link>
                  </li>
                  <li className="my-2">
                    <Link
                      to={routes.privacyPolicy}
                      className="text-decoration-none text-secondary text-capitalize fs-14">
                      Privacy Policy
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col my-3 hide-ipad">
              <h6 className="mb-4 text-capitalize fw-600">Connect With Us</h6>
              <div className="footer-link">
                <ul className="p-0 m-0 list-style-none">
                  <li className="my-2">
                    <a
                      href={SOCIAL_MEDIA.LINKEDIN}
                      target="_blank"
                      className="text-decoration-none text-secondary text-capitalize fs-14"
                      rel="noreferrer">
                      <i className="fa-brands fa-linkedin me-2 footer-icon"></i>
                    </a>
                    <a
                      href={SOCIAL_MEDIA.TWITTER}
                      target="_blank"
                      className="text-decoration-none text-secondary text-capitalize fs-14"
                      rel="noreferrer">
                      <i className="fa-custom-icon fa-x-twitter fa-fw me-2 footer-icon"></i>
                    </a>
                    <a
                      href={SOCIAL_MEDIA.FACEBOOK}
                      target="_blank"
                      className="text-decoration-none text-secondary text-capitalize fs-14"
                      rel="noreferrer">
                      <i className="fa-brands fa-facebook me-2 footer-icon"></i>
                    </a>
                    <a
                      href={SOCIAL_MEDIA.PINTREST}
                      target="_blank"
                      className="text-decoration-none text-secondary text-capitalize fs-14"
                      rel="noreferrer">
                      <i className="fa-brands fa-pinterest footer-icon"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <hr className="border-secondary border-opacity-50 mt-0" />
        <div className="container">
          <div className="row row-cols-1 row-cols-xl-3 row-cols-lg-4 row-cols-md-3 align-items-center">
            <div className="col">
              <p className="mb-0 lh-base  fs-11">
                © {new Date().getFullYear()} VeriiPro. All Rights Reserved
              </p>
            </div>
            <div className="col offset-lg-4 text-center">
              <a
                href="https://www.dmca.com/Protection/Status.aspx?id=0651bc13-7811-4024-815a-5fc3c8e08e25&refurl=https://veriipro.com/"
                target="_blank"
                title="DMCA.com Protection Status"
                className="dmca-badge"
                rel="noreferrer">
                <img
                  src="https://images.dmca.com/Badges/dmca_protected_sml_120s.png?ID=0651bc13-7811-4024-815a-5fc3c8e08e25"
                  alt="DMCA.com Protection Status"
                  width={109}
                  height={22}
                />
              </a>
              <script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js"> </script>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
