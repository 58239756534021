import React, { Fragment, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import Avatar from 'react-avatar';
import { Dropdown, Navbar } from 'react-bootstrap';
import { MessageIconBlack, MessageIconBlue, logo, user } from '../../constants/images';
import routes from '../../constants/routes';
import { getHeaderData } from '../../store/employer/actions';
import { logOut } from '../../store/auth/actions';
import CustomToolTip from './CustomToolTip';
import { removeCookie } from '../../utils/cookie-service';

function Header(props) {
  const navigate = useNavigate();

  const { getHeaderData, user, headerData } = props;

  const onLogOut = () => {
    const { logOut } = props;
    logOut();
    removeCookie('redirectPath');
    navigate(routes.login);
  };

  return (
    <Fragment>
      {!user.isAuthenticated && <PublicHeader />}
      {user.isAuthenticated && (
        <EmployerHeader
          logOut={onLogOut}
          navigate={navigate}
          getHeaderData={getHeaderData}
          headerData={headerData}
          user={user.currentUser}
        />
      )}
    </Fragment>
  );
}

function PublicHeader(props) {
  const { pathname } = useLocation();
  return (
    <div className="nav-bar position-sticky top-0 bg-white border-bottom">
      <div className="container-fluid">
        <Navbar expand="lg">
          <div className="container-fluid">
            <Link className="navbar-brand me-5" to={routes.candidateHome}>
              <img src={logo} alt="VeriiPro Logo" className="logo pe-none" />
            </Link>
            <Navbar.Toggle className="shadow-none me-0" aria-controls="basic-navbar-nav" />
            <Navbar.Collapse className="nav-bar-a" id="navbarSupportedContent">
              <ul className="navbar-nav me-auto d-lg-flex align-items-lg-center">
                <li className="nav-item me-3">
                  <Link
                    className="nav-link text-dark text-uppercase fw-500"
                    aria-current="page"
                    to={routes.about}>
                    about us
                  </Link>
                </li>
                <li className="nav-item me-3">
                  <Link className="nav-link text-dark text-uppercase fw-500" to={routes.webJobs}>
                    jobs
                  </Link>
                </li>
                <li className="nav-item me-3">
                  <a
                    href="https://blog.veriipro.com/"
                    target="_blank"
                    className="nav-link text-dark text-uppercase fw-500">
                    blog
                  </a>
                </li>
              </ul>
              <ul className="navbar-nav ms-auto d-lg-flex align-items-lg-center">
                <li className="nav-item me-3 my-lg-0 my-2 d-flex">
                  <Link
                    className={`btn-login ${pathname.includes('login') ? 'btn-login-active' : ''}`}
                    to={routes.employerLogin}>
                    Employer Login
                  </Link>
                </li>
                <li className="nav-item me-3 my-lg-0 my-2 d-flex">
                  <Link
                    className={`btn-lightblue text-uppercase ${!pathname.includes('login') ? 'btn-lightblue-active' : ''}`}
                    to={routes.home}>
                    Register Account
                  </Link>
                </li>

                {/* <li className="nav-item me-3 my-lg-0 my-2 d-flex">
                  <Link
                    className="nav-link text-uppercase px-4 rounded-pill bg-blue text-white fw-500 border"
                    to={routes.contactUs}>
                    Get Started
                  </Link>
                </li> */}
                <Dropdown variant="link" className="nav-item dropdown d-flex">
                  <a
                    className="btn btn-light rounded-0 fw-500 text-uppercase text-decoration-none"
                    href={routes.candidateRegister}>
                    <img src={user} alt="User Icon" className="icon-size me-2 pe-none" />
                    JOB SEEKERS
                  </a>
                  <Dropdown.Toggle
                    split
                    className="nav-link dropdown-toggle bg-light justify-content-center d-flex align-items-center border-0 rounded-0"
                  />
                  <Dropdown.Menu className="border-0 rounded-3 shadow mt-2 position-absolute">
                    <Dropdown.Item
                      className="text-capitalize fw-500 text-secondary"
                      // target="_blank"
                      href={routes.candidateLogin}>
                      Login
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="text-capitalize fw-500 text-secondary"
                      // target="_blank"
                      href={routes.candidateRegister}>
                      Sign Up
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </ul>
            </Navbar.Collapse>
          </div>
        </Navbar>
      </div>
    </div>
  );
}

function EmployerHeader(props) {
  useEffect(() => {
    async function fetchData() {
      const { getHeaderData } = props;
      await getHeaderData();
    }

    fetchData();
  }, []);

  const { headerData } = props;

  const messageNotification = () => {
    return (
      <ul className="navbar-nav ms-auto mb-lg-0 login-signup-btn align-items-lg-center">
        <CustomToolTip
          text={`You have ${headerData?.totalMessageCount} unread messages`}
          placement="bottom">
          <Link className="me-3 mt-1 position-relative cursor-pointer" to={routes.viewMessages}>
            <div className="text-dark text-uppercase fw-500 ">
              <img
                src={MessageIconBlack}
                className="message-icon"
                onMouseOver={(e) => (e.target.src = MessageIconBlue)}
                onMouseOut={(e) => (e.target.src = MessageIconBlack)}
              />
              <div className="notification-count">
                {headerData?.totalMessageCount > 0 && (
                  <span className="header-count ">{headerData?.totalMessageCount}</span>
                )}
              </div>
            </div>
          </Link>
        </CustomToolTip>
      </ul>
    );
  };

  return (
    <React.Fragment>
      <div className="nav-bar position-sticky top-0 bg-white border-bottom">
        <div className="container">
          <Navbar className="py-lg-0" expand="lg">
            <div className="container-fluid">
              <Link className="navbar-brand me-5" to={routes.dashboard}>
                <img src={logo} alt="VeriiPro Logo" className="logo pe-none" />
              </Link>
              <div className="ms-auto mx-2 d-lg-none">{messageNotification()}</div>
              <Navbar.Toggle className="shadow-none me-0" aria-controls="basic-navbar-nav" />
              <Navbar.Collapse className="nav-bar-a" id="navbarSupportedContent">
                <ul className="navbar-nav me-auto mb-lg-0 align-items-lg-center">
                  <li className="nav-item me-3">
                    <Link
                      className="nav-link text-dark text-uppercase fw-500"
                      to={routes.dashboard}>
                      Dashboard
                    </Link>
                  </li>
                  <li className="nav-item me-3 candidate-menu-item">
                    <span className="nav-link text-dark text-uppercase fw-500 candidates-dropdown">
                      jobs <i className="fa fa-chevron-down ms-2"></i>
                    </span>
                    <ul className="dropdown-menu candidate-menu rounded-3 border-0 shadow mt-0 left-auto">
                      <li>
                        <Link
                          className="dropdown-item  fw-500 text-secondary"
                          to={routes.postJob + '/'}>
                          Post a Job
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item text-capitalize fw-500 text-secondary"
                          to={routes.jobs + routes.myJobs}>
                          Manage Jobs
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item text-capitalize fw-500 text-secondary"
                          to={routes.draftJob}>
                          Draft Jobs
                        </Link>
                      </li>
                      {/* <li>
                        <Link
                          className="dropdown-item text-capitalize fw-500 text-secondary"
                          to={routes.jobs + routes.teamJob}>
                          My Team Jobs
                        </Link>
                      </li> */}
                    </ul>
                  </li>

                  <li className="nav-item me-3 candidate-menu-item">
                    <span className="nav-link text-dark text-uppercase fw-500 candidates-dropdown">
                      Candidates <i className="fa fa-chevron-down ms-2"></i>
                    </span>
                    <ul className="dropdown-menu candidate-menu rounded-3 border-0 shadow mt-0 left-auto">
                      {/* <li>
                                            <Link className="dropdown-item text-capitalize fw-500 text-secondary" to={routes.premiumRequest}>Premium Request</Link>
                                        </li> */}
                      <li>
                        <Link
                          className="dropdown-item text-capitalize fw-500 text-secondary"
                          to={routes.candidateSearch}>
                          Search Candidates
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item text-capitalize fw-500 text-secondary"
                          to={routes.savedCandidate}>
                          Saved Candidates
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item text-capitalize fw-500 text-secondary"
                          to={routes.downloadedCandidate}>
                          Downloaded Candidates
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item text-capitalize fw-500 text-secondary"
                          to={routes.taggedCandidate}>
                          Candidate Notes
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item text-capitalize fw-500 text-secondary"
                          to={routes.candidateRecentSearchHistory}>
                          Candidate Search History
                        </Link>
                      </li>
                    </ul>
                  </li>

                  {/* <li className="nav-item me-3">
                    <Link
                      className="nav-link text-dark text-uppercase fw-500"
                      to={routes.interview}>
                      interview
                    </Link>
                  </li> */}
                </ul>
                <ul className="navbar-nav ms-auto mb-lg-0 login-signup-btn align-items-lg-center">
                  <div className="d-none d-lg-block">{messageNotification()}</div>
                  <Dropdown variant="link" className="nav-item dropdown ">
                    <Dropdown.Toggle
                      variant="link"
                      className="nav-link dropdown-toggle d-flex align-items-center fw-500">
                      <Avatar
                        size="50"
                        name={headerData?.name}
                        src={
                          headerData?.recruiter_image && headerData?.recruiter_image !== ''
                            ? `${headerData?.image_url}/${headerData?.recruiter_image}`
                            : 'https://app.veriipro.com/images/profile-picture-blank.png'
                        }
                        alt="User Icon"
                        className="userprofile me-2 rounded-pill"
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu rounded-3 border-0 shadow mt-0 menu-right">
                      <Dropdown.Item as="div" className="mb-2">
                        <Link
                          className="text-capitalize text-decoration-none fw-500 text-secondary"
                          to={routes.updateProfile}>
                          User Profile
                        </Link>
                      </Dropdown.Item>
                      <Dropdown.Item as="div" className="mb-2">
                        <Link
                          className="text-capitalize text-decoration-none fw-500 text-secondary"
                          to={routes.updateCompanyProfile}>
                          Company Profile
                        </Link>
                      </Dropdown.Item>
                      <Dropdown.Item as="div" className="mb-2">
                        <Link
                          className="text-capitalize text-decoration-none fw-500 text-secondary"
                          to={routes.viewMessages}>
                          Messages
                        </Link>
                      </Dropdown.Item>
                      <Dropdown.Item as="div" className="mb-2">
                        <Link
                          className="text-capitalize text-decoration-none fw-500 text-secondary"
                          to={routes.changePassword}>
                          Change Password
                        </Link>
                      </Dropdown.Item>
                      {/* <Dropdown.Item as="div" className="mb-2">
                        <Link
                          className="text-capitalize text-decoration-none fw-500 text-secondary"
                          to={routes.manageSubUser}>
                          Manage Sub User
                        </Link>
                      </Dropdown.Item> */}
                      {/* <Dropdown.Item as="div" className="mb-2">
                        <Link
                          className="text-capitalize text-decoration-none fw-500 text-secondary"
                          to={routes.orderHistory}>
                          Order History
                        </Link>
                      </Dropdown.Item> */}
                      {/* <Dropdown.Item as="div" className="mb-2">
                        <Link
                          className="text-capitalize text-decoration-none fw-500 text-secondary"
                          to={routes.allocationHistory}>
                          Allocation History
                        </Link>
                      </Dropdown.Item> */}
                      {/* <Dropdown.Item as="div" className="mb-2">
                        <Link
                          className="text-capitalize text-decoration-none fw-500 text-secondary"
                          to={routes.subscriptionHistory}>
                          Subscription plan
                        </Link>
                      </Dropdown.Item> */}
                      <Dropdown.Item
                        as="div"
                        className="dropdown-item text-capitalize text-blue fw-600 cursor"
                        onClick={props.logOut}>
                        Log Out
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </ul>
              </Navbar.Collapse>
            </div>
          </Navbar>
        </div>
      </div>
    </React.Fragment>
  );
}

export default connect((state) => ({ user: state.auth, headerData: state.employer.headerData }), {
  logOut,
  getHeaderData
})(Header);
