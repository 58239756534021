export const LOGIN = 'LOGIN';
export const DEFAULT_ACTION = 'DEFAULT_ACTION';
export const LOG_OUT = "LOG_OUT";
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const REQUEST_OTP = 'REQUEST_OTP';
export const RESEND_OTP = 'RESEND_OTP';
export const VERIFY_OTP = 'VERIFY_OTP';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const FORGET_VERIFY_OTP = 'FORGET_VERIFY_OTP';
export const CONTACT_US = 'CONTACT_US';