import { UPDATE_HEADER_DATA, UPDATE_CANDIDATE_LIST, UPDATE_CANDIDATE_FILTERS } from '../mutations';

const initialState = {
    candidateList: [],
    candidateFilters: {},
    headerData: {}
};

export default function employerReducer(state = initialState, action) {
    switch (action.type) {
        case UPDATE_CANDIDATE_LIST:
            return { ...state, candidateList: action.payload };
        case UPDATE_CANDIDATE_FILTERS:
            return { ...state, candidateFilters: action.payload };
        case UPDATE_HEADER_DATA:
            return { ...state, headerData: action.payload }
        default:
            return state;
    }
};
